import { SET_NAVBAR_LINKS } from "../types";

export const NAVBAR_NAVIGATION = (view = "list") => [
  {
    slug: "schedule",
    order: 1,
    title: "schedule",
    path: `schedule/${view}`,
    isDefault: true,
  },
  {
    slug: "subscription",
    order: 2,
    title: "subscriptions",
    path: "subscription",
    isDefault: true,
  },
  {
    slug: "blog",
    order: 3,
    title: "blog",
    path: "blog",
  },
  {
    slug: "announce",
    order: 4,
    title: "announces",
    path: "announce",
  },
];

const navbarLinks = (
  state = NAVBAR_NAVIGATION().filter(({ isDefault }) => !!isDefault),
  { type, pagesLinks = [], scheduleDefaultView }
) => {
  switch (type) {
    case SET_NAVBAR_LINKS:
      return NAVBAR_NAVIGATION(scheduleDefaultView)
        .filter(({ slug }) => pagesLinks.some((el) => el == slug))
        .sort(({ order: orderA }, { order: orderB }) => orderA - orderB);

    // case SET_SCHEDULE_VIEW:
    //   return state.map((nav) =>
    //     nav.slug === "schedule"
    //       ? {
    //           ...nav,
    //           path: `schedule/${scheduleDefaultView}`,
    //         }
    //       : nav
    //   );

    default:
      return state;
  }
};

export default navbarLinks;
